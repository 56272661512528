import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page } from 'react-pdf'

export default class PdfView extends React.Component {
  static propTypes = {
    filePath: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      numPages: 0,
      pageNumber: 1
    }
    this.pdfWrapperRef = React.createRef()
    // Rerender, and resize the PDF viewer when the window resizes
    window.onresize = () => this.forceUpdate()
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState(_prevState => ({ numPages, pageNumber: 1 }))
  }

  changePage(offset) {
    this.setState(prevState => ({ pageNumber: prevState.pageNumber + offset }))
  }

  previousPage() {
    this.changePage(-1)
  }

  nextPage() {
    this.changePage(1)
  }

  render() {
    const { pageNumber, numPages } = this.state

    const previousDisabled = pageNumber <= 1
    const nextDisabled = pageNumber >= numPages

    return (
      <div ref={this.pdfWrapperRef}>
        <Document
          file={this.props.filePath}
          onLoadSuccess={(props) => this.onDocumentLoadSuccess(props)}
        >
          <Page pageNumber={pageNumber} width={this.pdfWrapperRef.current?.getBoundingClientRect().width}>
            <div className='react-pdf__Page-Controls'>
              <button
                type="button"
                className={`btn btn--slider-prev btn--slider-controls ${previousDisabled ? 'disabled' : ''}`}
                disabled={previousDisabled}
                onClick={() => this.previousPage()}
              >
                <span className="material-icons-outlined icon--sm icon--space-right">arrow_back</span><span className="hide-xs">Previous Slide</span>
              </button>
              <p className="react-pdf__Pagination">
                <span className="hide-xs">Page </span>{pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </p>
              <button
                type="button"
                className={`btn btn--slider-next btn--slider-controls ${nextDisabled ? 'disabled' : ''}`}
                disabled={nextDisabled}
                onClick={() => this.nextPage()}
              >
                <span className="hide-xs">Next Slide</span><span className="material-icons-outlined icon--sm icon--space-left">arrow_forward</span>
              </button>
            </div>
          </Page>
        </Document>
      </div>
    )
  }
}
