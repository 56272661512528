import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import Bridge from './helpers/Bridge'
import { Turbo } from "@hotwired/turbo-rails"
import './controllers'
import ReactRailsUJS from 'react_ujs'
import './initializers/honeybadger'
import 'trix'
import '@rails/actiontext'
import RolemodelConfirm from './helpers/rolemodel-confirm'
import RolemodelModal from './helpers/rolemodel-modal'
import DirectUploadEvents from './helpers/direct-upload-events'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.mjs'

Rails.start()
ActiveStorage.start()

window.Turbo = Turbo
window.bridge = Bridge

// Temp fix for turbo and react. Should be update in later version of react-rails
// See https://github.com/reactjs/react-rails/issues/1103 for details
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);

// This workaround is to address this webkit bug in safari
// https://bugs.webkit.org/show_bug.cgi?id=222657
document.addEventListener('turbo:load', () => {
  // find all video elements
  document.querySelectorAll('div[data-video]').forEach((videoElement) => {
    const newVideoElement = document.createElement('video')
    Object.entries(videoElement.attributes).forEach((attributeNode) => {
      const attribute = attributeNode[1]
      newVideoElement.setAttribute(attribute.name, attribute.value)
    })

    videoElement.parentElement.appendChild(newVideoElement)
    videoElement.remove()
    newVideoElement.style.visibility = 'visible'
  })
})

const onPageLoad = () => {
  // RolemodelPanel.init()
  RolemodelModal.init()
  RolemodelConfirm.init()
}

document.addEventListener('turbo:load', onPageLoad)
document.addEventListener('rolemodel:pageLoad', onPageLoad)

DirectUploadEvents.init()
